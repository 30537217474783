import React from "react"
import { graphql } from "gatsby"

import Collection from "../components/About/Collection.Author"
import "./animation.css"

export const productQuery = graphql`
  query allLeaderships($id: String!) {
    allMarkdownRemark(filter: { frontmatter: {}, id: { eq: $id } }) {
      edges {
        node {
          frontmatter {
            templateKey
            date
            name
            role
            expert_logo
            content_role
            description
            avatar
            contact {
              email
              phone_number
              calendly
            }
          }
          id
        }
      }
    }
  }
`

const DetailsLeadership = ({ data }) => {
  const node = data.allMarkdownRemark.edges[0].node
  const subheader = `We're a dedicated group of technology pros who develop mission-critical software solutions to enter, store and analyze data. And we've been consulting for Quick Base users since Quick Base was invented.Whether you need a new application, help integrating with QuickBooks or Excel, porting legacy applications to Quick Base, syncing with mobile devices or more, we can efficiently and affordably find the right solution for your business. We provide customized training and phone support.`
  return <Collection collectionInfo={node} subheader={subheader} />
}

export default DetailsLeadership
