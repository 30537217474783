import React from "react"
import { Stack, Main } from "@layout"
import { Box, Card } from "theme-ui"
import PageTitle from "../Shared/PageTitle"
import { InlineWidget } from "react-calendly"
import Divider from "@components/Divider"
import Seo from "@widgets/Seo"
import AuthorExpanded from "./AuthorExpanded"
import MemphisPattern from "@components/MemphisPattern"
import "./animation.css"

const styles = {
  main: {
    zIndex: 0,
  },
  card: {
    position: `relative`,
    zIndex: -1,
  },
  wrapper: {
    flexDirection: [`column`, `row`],
    position: `relative`,
    zIndex: 3,
  },
  pattern: {
    borderRadius: `lg`,
    zIndex: -2,
  },
  gradient: {
    size: `full`,
    borderRadius: `lg`,
    position: `absolute`,
    left: 0,
    top: 0,
    zIndex: -1,
    background: [
      `linear-gradient(0deg, white 20%, transparent 80%)`,
      `linear-gradient(270deg, white 20%, transparent 100%)`,
    ],
  },
  pageSettings: {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "00a2ff",
    textColor: "4d5055",
  },
  inlineWidget: {
    height: "87vh",
    zIndex: "5",
  },
}

const Collection = ({ collectionInfo: { frontmatter, id }, subheader }) => (
  <>
    <Seo title={frontmatter.name} />
    <Divider space={3} />
    {/*<Stack effect="fadeInDown">
      <PageTitle header="Who We Are" subheader={subheader} />
    </Stack>*/}
    <div className="animateInDown">
      <PageTitle header="Who We Are" subheader={subheader} />
    </div>
    <Divider space={3} />
    <div className="animateInUp">
      <Main style={styles.main}>
        <AuthorExpanded author={frontmatter} details={true} />
        <Divider space={3} />
        <Card variant="paper" sx={styles.card}>
          <div>
            <InlineWidget
              pageSettings={styles.pageSettings}
              styles={styles.inlineWidget}
              url={`https://calendly.com/${frontmatter.contact.calendly}`}
            />
          </div>
          <Box sx={styles.gradient} />
          <MemphisPattern sx={styles.pattern} />
        </Card>
      </Main>
    </div>
    <Divider space={3} />
  </>
)

export default Collection
